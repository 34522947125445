import React from "react"
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { homePage } }, location }) {

	return (
		<>
			<SEO title="Home" description={ homePage.seoDescription } pathname={ location.pathname } />

			{ homePage && homePage.content &&
				<DynamicZone components={ homePage.content } />
			}
		</>
	)
}

export const query = graphql`
	query testQuery {
		strapi {
			homePage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentHomeImageBanner {
						id
						heading
						ctaText
						ctaLink
						alignImageLeft
						imageMainContent
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentGeneralHeroBanner {
						id
						heroDescription
						heroTitle
						imageFocus
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000, maxHeight: 500) {
										...GatsbyImageSharpFluid_noBase64
							 		}
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomeColourBanner {
						id
						backgroundColour
						ctaLink
						ctaText
						heading
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomePrimaryBanner {
						id
						ctaLink
						ctaText
						heading
					}
					... on StrapiQuery_ComponentHomeIllustrationBanner {
						id
						ctaLink
						ctaText
						heading
						illustration
						alignImageLeft
					}
					... on StrapiQuery_ComponentHomeProductCategoryGrid {
						id
						heading
						categories {
							id
							label
							thumbnail {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 720) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
							products {
								id
							}
							main_categories {
								id
								slug
							}
						}
					}
				}
			}
		}
	}
`